<template>
  <!-- 关于我们 -->
  <div class="aboutuser">
    <div
      class="current-nav centerText"
      :style="{
        background: `url(${
          guanyuData.背景图片
        }) 100%/100% no-repeat`
      }"
    >
      <h1>{{ guanyuData.标题 ? guanyuData.标题 : '关于我们' }}</h1>
      <h2>{{ guanyuData.小标题 }}</h2>
      <span>我们</span>
    </div>
    <div
      v-for="(item, idx) in navList"
      :key="idx"
      class="aboutuser-nav"
      :class="'nav' + idx"
    >
      <div class="current-title">
        <span>{{ item.title }}</span>
        <p>
          {{ item.text }}
        </p>
      </div>
      <ul class="current-nzsm" v-if="idx == 0">
        <li v-for="(el, i) in item.list" :key="i">
          <i :style="{ background: `url(${el.value}) no-repeat 0 0` }"></i>
          <p>{{ el.key }}</p>
        </li>
      </ul>
      <ul class="current-js" v-else-if="idx == 1">
        <li>
          <div class="current-js-left">
            <img :src="item.msgobj.公司图片" alt="" style="width:100%" />
          </div>
          <div class="current-js-right" v-html="item.msgobj.公司文本"></div>
        </li>
      </ul>
      <ul class="current-ryzz" v-else-if="idx == 2">
        <li v-for="(el, i) in item.list" :key="i">
          <img :src="el" alt="" style="width:100%" />
        </li>
      </ul>
      <div class="current-fzlc" v-else-if="idx == 3">
        <Carousel :status="1" :list="item.list"></Carousel>
      </div>
      <div class="current-tdsh" v-else-if="idx == 4">
        <div class="current-tdsh-slider">
          <ul>
            <li v-for="el in item.list[0].imgList" :key="el">
              <img :src="el" alt="" style="width:100%;height:100%;" />
            </li>
          </ul>
        </div>
        <ul class="current-tdsh-ul">
          <li
            class="centerText"
            v-for="(el, i) in item.list[0].iconList"
            :key="i"
          >
            <img :src="el.value" alt="" />
            <span>{{ el.key }}</span>
          </li>
        </ul>
        <div class="current-tdsh-td">
          <img
            v-for="el in item.list[0].sjImg"
            :key="el"
            :src="el"
            alt=""
            style="width:100%"
          />
        </div>
      </div>
      <div class="current-fwlc" v-else-if="idx == 5">
        <ul class="current-fwlc-left">
          <li
            v-for="(el, i) in item.list"
            :key="i"
            :class="[active == i ? 'active_on' : '']"
            @mouseover=";(active = i), (currentData = el)"
          >
            <div class="icon">
              <i :class="el.value"></i>
            </div>
            <div class="number">0{{ i + 1 }}</div>
            <div class="text">{{ el.key }}</div>
          </li>
        </ul>
        <div class="current-fwlc-right">
          <h1>{{ currentData.key }}</h1>
          <div class="line"></div>
          <div class="text" v-for="(el, i) in currentList" :key="i">
            <p v-if="i == active" v-html="el.value"></p>
          </div>
        </div>
      </div>
      <ul class="current-qyxz" v-else-if="idx == 6">
        <li v-for="(el, i) in item.list" :key="i">
          <img :src="el" alt="" style="width:100%" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Carousel from '@/common/Carousel/Carousel.vue'
import { getPageMsg } from '@/api/api'
export default {
  components: {
    Carousel
  },
  data () {
    return {
      guanyuData: {},
      navList: [
        {
          title: '',
          text: '',
          list: []
        },
        {
          title: '',
          text: '',
          msgobj: {}
        },
        {
          title: '',
          text: '',
          list: []
        },
        {
          title: '',
          text: '',
          list: [
            {
              dataList: []
            },
            {
              dataList: []
            }
          ]
        },
        {
          title: '',
          text: '',
          list: [
            {
              imgList: [],
              iconList: [],
              sjImg: []
            }
          ]
        },
        {
          title: '服务流程',
          text:
            '凭借多年的项目经验，与客户一起沟通，从客户的角度发现问题，找到良好解决方案。',
          list: []
        },
        {
          title: '30000+企业的信赖选择',
          text:
            '现在服务的上千家客户与我们一路同行，见证我们的成长；未来，我们一起分享成功的喜悦。',
          list: []
        }
      ],
      currentData: {},
      currentList: [],
      active: 0
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.guanyuData = res.data.data.基础信息
          that.navList.forEach((el, idx) => {
            if (idx == 0) {
              el.title = res.data.data.能做什么.能做什么
              el.text = res.data.data.能做什么.文本
              el.list = res.data.data.能做什么.服务数组
            } else if (idx == 1) {
              el.title = res.data.data.公司简介.公司简介
              el.text = res.data.data.公司简介.公司介绍
              el.msgobj = res.data.data.公司简介
            } else if (idx == 2) {
              el.title = res.data.data.荣誉资质.荣誉资质
              el.text = res.data.data.荣誉资质.文本
              el.list = res.data.data.荣誉资质.资质图片
            } else if (idx == 3) {
              el.title = res.data.data.发展历程.发展历程
              el.text = res.data.data.发展历程.文本
              el.list[0].dataList = res.data.data.发展历程.数组
              el.list[1].dataList = res.data.data.发展历程.FZ数组
            } else if (idx == 4) {
              el.title = res.data.data.团队生活.团队生活
              el.text = res.data.data.团队生活.文本
              el.list[0].iconList = res.data.data.团队生活.ICON
              el.list[0].imgList = res.data.data.团队生活.IMG
              el.list[0].sjImg = res.data.data.团队生活.YDIMG
            } else if (idx == 5) {
              el.title = res.data.data.服务流程.服务流程
              el.text = res.data.data.服务流程.文本
              el.list = res.data.data.服务流程.fwlcList
              that.currentData = el.list[0]
              that.currentList = res.data.data.服务流程.fwlcList2
            } else if (idx == 6) {
              el.title = res.data.data.企业选择.企业选择
              el.text = res.data.data.企业选择.文本
              el.list = res.data.data.企业选择.IMG
            }
          })
        }
      })
    },
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.aboutuser {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .aboutuser-nav {
    padding-bottom: 80px;
    .current-nzsm {
      display: flex;
      li {
        width: 17%;
        text-align: center;
        color: #000;
        margin: 0 1.5%;
        i {
          display: inline-block;
          width: 65px;
          height: 61px;
          margin: 4% auto;
          background-position-x: center !important;
        }
      }
    }
    .current-js {
      width: 86%;
      margin: 0 auto;
      li {
        width: 100%;
        display: flex;
        .current-js-left {
          width: 50%;
          padding-right: 80px;
          box-sizing: border-box;
        }
        .current-js-right {
          width: 50%;
          color: #666666;
          font-size: 18px;
          text-align: justify;
          padding-top: 10px;
          line-height: 1.9;
        }
      }
    }
    .current-ryzz {
      display: flex;
      max-width: 86%;
      margin: 0 auto;
      li {
        width: 25%;
      }
    }
    .current-fzlc {
    }
    .current-tdsh {
      width: 86%;
      height: 548px;
      margin: 0 auto;
      position: relative;
      .current-tdsh-slider {
        width: 61.5%;
        height: 100%;
        margin: 0 auto;
        margin-left: 23%;
        user-select: none;
        touch-action: pan-y;
        ul {
          overflow: hidden;
          outline: none;
          width: 100%;
          height: 100%;
          min-height: 1px;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            margin-left: 0.9%;
            margin-bottom: 6px;
            overflow: hidden;
            img {
              transition: 0.5s;
            }
          }
          li:nth-child(1),
          li:nth-child(5) {
            width: 12.859%;
          }
          li:nth-child(2),
          li:nth-child(3) {
            width: 30.713%;
          }
          li:nth-child(4),
          li:nth-child(6) {
            width: 21.925%;
          }
          li:nth-child(5),
          li:nth-child(6) {
            margin-bottom: 0;
          }
        }
      }
      .current-tdsh-ul {
        li {
          background: #e52123;
          color: #fff;
          position: absolute;
          flex-direction: column;
          span {
            font-size: 30px;
            margin-top: 20px;
          }
        }
        li:nth-child(1) {
          left: 0;
          width: 23%;
          height: 100%;
          top: 0;
        }
        li:nth-child(2) {
          top: 0;
          right: 0;
          width: 15%;
          height: 100%;
          background: url(~@/assets/image/gywm/life-d2-bg.jpg) no-repeat center
            center;
          background-size: cover;
        }
        li:nth-child(3) {
          bottom: 0;
          width: 38.5%;
          left: 32%;
          height: 50%;
        }
      }
      .current-tdsh-td {
        display: none;
        img {
          margin-bottom: 0.5rem;
        }
      }
    }
    .current-fwlc {
      width: 86%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .current-fwlc-left {
        width: 72%;
        border-top: solid 1px #eaeaea;
        border-left: solid 1px #eaeaea;
        box-sizing: border-box;
        background-color: #ededed;
        display: flex;
        flex-wrap: wrap;
        li {
          width: 25%;
          height: 240px;
          border-right: solid 1px #eaeaea;
          border-bottom: solid 1px #eaeaea;
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s;
          .icon {
            height: 100px;
            margin-top: 30px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100px 100px;
            i {
              display: flex;
              justify-content: center;
              flex-direction: column;
              border-radius: 50%;
              margin: auto;
              width: 100px;
              height: 100px;
              background-color: #f5f5f5;
              font-size: 38px;
              color: #555;
            }
          }
          .number {
            line-height: 60px;
            font-size: 60px;
            color: #e2e2e2;
            margin-top: 10px;
          }
          .text {
            line-height: 36px;
            height: 36px;
            font-size: 20px;
            color: #000000;
            margin-top: -20px;
            background: #ededed;
            position: relative;
            z-index: 5;
          }
        }
        li:hover {
          z-index: 10;
          box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.1);
          position: relative;
          transform: translate3d(0, -2.5px, 0);
          .icon {
            i {
              background-color: #e62022;
              color: #fff;
            }
          }
        }
        .active_on {
          .icon {
            i {
              background-color: #e62022;
              color: #fff;
            }
          }
        }
        li:nth-child(1),
        li:nth-child(3),
        li:nth-child(6),
        li:nth-child(8) {
          background: #fff;
          .text {
            background: #fff;
          }
        }
      }
      .current-fwlc-right {
        width: 25%;
        height: 480px;
        background: url(~@/assets/image/gywm/life-fwlc-bg.jpg) no-repeat;
        padding: 80px 78px 0 78px;
        box-sizing: border-box;
        transition: all 0.3s;
        h1 {
          line-height: 1.2;
          font-size: 40px;
          color: #fff;
        }
        .line {
          width: 50px;
          height: 4px;
          background: #fff;
          margin-top: 18px;
        }
        .text {
          line-height: 30px;
          font-size: 20px;
          color: #fff;
          margin-top: 30px;
          p {
            font-size: 18px;
            color: #fff;
          }
        }
      }
      .current-fwlc-right:hover {
        margin-top: -10px;
      }
    }
    .current-qyxz {
      display: flex;
      flex-wrap: wrap;
      border-right: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf;
      width: 86%;
      margin: 0 auto;
      li {
        width: 20%;
        border-left: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        box-sizing: border-box;
        img {
          opacity: 0.8;
          filter: grayscale(100%);
          transition: 0.3s;
        }
      }
      li:hover img {
        opacity: 1;
        filter: none;
      }
    }
  }
  .aboutuser-nav:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
}
</style>
